<template>
  <ul
    class="w-full flex flex-col lg:flex-row border-[1.5px] md:border-2 border-primary"
  >
    <li
      class="lg:w-1/3 w-full lg:aspect-square flex flex-col border-b-[1.5px] md:border-b-2 lg:border-b-0 lg:border-r-2 border-primary"
    >
      <div class="w-full p-5 md:p-7">
        <span class="block h-8 mb-2 w-36 skeleton"></span>
        <span class="block w-24 h-6 mb-10 skeleton"></span>
        <div class="flex flex-col w-full lg:justify-center lg:grow">
          <div class="mx-auto mb-10 w-28 aspect-square skeleton md:w-36"></div>
          <div class="flex w-full">
            <span class="block w-full py-6 mr-2 bg-primary"></span>
            <span class="block w-full py-6 bg-primary"></span>
          </div>
        </div>
      </div>
    </li>
    <li
      class="lg:w-1/3 w-full lg:aspect-square flex flex-col border-b-[1.5px] md:border-b-2 lg:border-b-0 lg:border-r-2 border-primary"
    >
      <div class="w-full p-5 md:p-7">
        <span class="block h-8 mb-2 w-36 skeleton"></span>
        <span class="block w-24 h-6 mb-10 skeleton"></span>
        <div class="flex flex-col w-full lg:justify-center lg:grow">
          <div class="mx-auto mb-10 w-28 aspect-square skeleton md:w-36"></div>
        </div>
      </div>
    </li>
    <li class="flex flex-col w-full lg:w-1/3">
      <div class="w-full p-5 md:p-7">
        <span class="block h-8 mb-2 w-36 skeleton"></span>
        <span class="block w-24 h-6 mb-10 skeleton"></span>
        <div class="flex flex-col w-full lg:justify-center lg:grow">
          <div class="mx-auto mb-10 w-28 aspect-square skeleton md:w-36"></div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HomeProcessSkeleton',
};
</script>
