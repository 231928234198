import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import './common/global_methods';
import VueMeta from 'vue-meta';

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  refreshOnceOnNavigation: false,
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
