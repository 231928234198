<template>
  <ul class="relative z-10 flex justify-end w-full h-fit">
    <li
      data-carousel-item
      class="case_card w-[330px] sm:w-[440px] flex-shrink-0 relative z-10"
      v-for="item in 4"
      :key="`skeleton_${item}`"
    >
      <div class="relative w-full h-full">
        <div
          class="relative z-10 w-full overflow-hidden aspect-square skeleton"
        ></div>
        <div
          class="absolute bottom-0 z-10 flex flex-col justify-end w-full h-full p-7"
        >
          <span class="block w-2/3 h-5 mb-3 bg-black">
            <span class="block w-full h-full skeleton opacity-90"></span>
          </span>

          <span class="block w-full h-10 mb-3 bg-black">
            <span class="block w-full h-full skeleton opacity-90"></span>
          </span>

          <span class="block w-20 h-6 bg-black">
            <span class="block w-full h-full skeleton opacity-90"></span>
          </span>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HomeCaseListSkeleton',
};
</script>
