import { gsap } from "@/gsap/gsap_loader";

export class header_marquee_animation {
  constructor(el) {
    this.el = el;
    this.text = el.querySelectorAll("p");
    this.timeline = null;
    this.reset();
    window.addEventListener("resize", () => {
      this.reset();
      this.setup();
    });
  }

  reset() {
    this.timeline != null ? this.timeline.kill() : "";
    gsap.set(this.text, { x: "-100%", left: 0 });
  }

  setup() {
    let marquee_duration = 5;
    let move_width = this.text[0].getBoundingClientRect().width;
    let start_pos = window.innerWidth;
    const window_size = window.innerWidth;
    if (window_size >= 1280) {
      marquee_duration = 40;
    } else if (window_size >= 768) {
      marquee_duration = 20;
    } else {
      marquee_duration = 20;
    }

    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline();
    this.timeline.fromTo(
      this.text,
      {
        x: start_pos + "px",
      },
      {
        x: move_width * -1 + "px",
        ease: "none",
        duration: marquee_duration,
        repeat: -1,
      },
      "first"
    );
  }
}
