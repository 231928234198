<template>
  <section class="relative z-10 w-full max-w-screen-xl mx-auto">
    <div
      class="relative z-10 w-full p-7 md:p-10 lg:p-14 xl:pr-28 xl:py-20 bg-bg_white"
    >
      <header class="w-full mb-10">
        <h2 class="text-2xl font-black md:text-3xl xl:text-4xl text-primary">
          立即免費諮詢
        </h2>
      </header>

      <form id="ApplyForm" class="flex flex-wrap md:-mx-5">
        <label
          for="name"
          class="block w-full mb-5 opacity-100 md:w-1/2 md:px-5 hover:opacity-80 btn_hover"
        >
          <p class="pl-1 mb-3 text-sm font-bold input_title text-primary">
            姓名
          </p>
          <input
            id="InputName"
            type="text"
            name="name"
            v-model="form_data.name"
            class="block w-full py-4 px-5 rounded bg-white placeholder:text-[#c8c8c8] focus:outline-none focus:ring-main_hover focus:ring-1"
            placeholder="請填入真實姓名"
          />
          <p
            v-if="GetError('name')"
            class="pl-2 mt-3 text-xs error_msg text-main_error"
          >
            ＊請輸入您的姓名
          </p>
        </label>
        <label
          for="line_id"
          class="block w-full mb-5 opacity-100 md:w-1/2 md:px-5 hover:opacity-80 btn_hover"
        >
          <p class="pl-1 mb-3 text-sm font-bold input_title text-primary">
            LINE ID
          </p>
          <input
            id="InputLineId"
            type="text"
            name="line_id"
            v-model="form_data.line_id"
            class="block w-full py-4 px-5 rounded bg-white placeholder:text-[#c8c8c8] focus:outline-none focus:ring-main_hover focus:ring-1"
            placeholder="請填入您的 LINE ID"
          />
          <p
            v-if="GetError('line_id')"
            class="pl-2 mt-3 text-xs error_msg text-main_error"
          >
            ＊請輸入您的 LINE ID
          </p>
        </label>
        <label
          for="phone"
          class="block w-full mb-5 opacity-100 md:w-1/2 md:px-5 hover:opacity-80 btn_hover"
        >
          <p class="pl-1 mb-3 text-sm font-bold input_title text-primary">
            聯絡電話
          </p>
          <input
            id="InputPhone"
            type="tel"
            name="phone"
            v-model="form_data.phone"
            class="block w-full py-4 px-5 rounded bg-white placeholder:text-[#c8c8c8] focus:outline-none focus:ring-main_hover focus:ring-1"
            placeholder="請填入聯絡電話"
          />
          <p
            v-if="GetError('phone')"
            class="pl-2 mt-3 text-xs error_msg text-main_error"
          >
            ＊請輸入聯絡電話
          </p>
        </label>
        <label
          class="relative block w-full mb-5 opacity-100 md:w-1/2 md:px-5 hover:opacity-80 btn_hover"
        >
          <p class="pl-1 mb-3 text-sm font-bold select_title text-primary">
            方便聯繫時間
          </p>
          <div class="relative w-full">
            <span
              class="absolute z-10 text-base transform -translate-y-1/2 pointer-events-none icon-chevron_down top-1/2 right-5 text-primary_dark"
            ></span>
            <select
              id="SelectTime"
              v-model="form_data.time"
              class="relative z-0 block w-full px-5 py-4 bg-white rounded opacity-100 appearance-none text-primary_dark placeholder:text-primary_dark focus:outline-none focus:ring-main_hover focus:ring-1 hover:opacity-80 btn_hover"
            >
              <option value="">方便聯絡時間</option>
              <option>不限時間皆可</option>
              <option>上午9:00-12:00</option>
              <option>中午12:00-13:00</option>
              <option>下午13:00-18:00</option>
              <option>晚上18:00-21:00</option>
            </select>
          </div>
          <p
            v-if="GetError('time')"
            class="pl-2 text-xs error_msg text-main_error"
          >
            ＊請選擇方便聯絡您的時間
          </p>
        </label>
        <label
          class="relative block w-full mb-10 opacity-100 md:w-1/2 md:px-5 md:mb-0 hover:opacity-80 btn_hover"
        >
          <p class="pl-1 mb-3 text-sm font-bold select_title text-primary">
            諮詢項目
          </p>
          <div class="relative w-full">
            <span
              class="absolute z-10 text-base transform -translate-y-1/2 pointer-events-none icon-chevron_down top-1/2 right-5 text-primary_dark"
            ></span>
            <select
              id="SelectQuestion"
              v-model="form_data.question"
              class="relative z-0 block w-full px-5 py-4 bg-white rounded appearance-none text-primary_dark placeholder:text-primary_dark focus:outline-none focus:ring-main_hover focus:ring-1"
            >
              <option value="">選擇您欲諮詢的項目</option>
              <option>小額貸款</option>
              <option>汽車貸款</option>
              <option>機車貸款</option>
              <option>二胎房貸</option>
              <option>企業貸款</option>
              <option>青年創業貸款</option>
              <option>其他</option>
            </select>
          </div>
          <p
            v-if="GetError('question')"
            class="pl-2 text-xs error_msg text-main_error"
          >
            ＊請選擇方便聯絡您的時間
          </p>
        </label>
        <label
          for="money"
          class="block w-full mb-5 opacity-100 md:w-1/2 md:px-5 hover:opacity-80 btn_hover"
        >
          <p class="pl-1 mb-3 text-sm font-bold input_title text-primary">
            需求資金
          </p>
          <input
            id="InputMoney"
            type="text"
            name="money"
            v-model="form_data.money"
            class="block w-full py-4 px-5 rounded bg-white placeholder:text-[#c8c8c8] focus:outline-none focus:ring-main_hover focus:ring-1"
            placeholder="請填入需求資金"
          />
          <p
            v-if="GetError('money')"
            class="pl-2 mt-3 text-xs error_msg text-main_error"
          >
            ＊請輸入需求資金
          </p>
        </label>
        <div class="flex items-end w-full md:px-5">
          <button
            type="button"
            @click="Validate"
            class="send_btn flex items-center justify-between w-full xl:w-60 md:h-fit md:ml-auto px-10 py-4 text-primary border-[1.5px] md:border-2 border-primary hover:text-white hover:bg-primary transition-colors duration-500"
          >
            <span class="inline-block mr-5 text-sm font-bold">送出表單</span>
            <span class="text-sm font-bold icon-arrow"></span>
          </button>
        </div>
      </form>
    </div>
    <div
      class="absolute top-0 bottom-0 left-0 hidden transform -translate-x-full apply_form_bg_el xl:block bg-bg_white"
    ></div>
  </section>
</template>

<script>
import { validPhone } from '@/common/validate';
import { postContact } from '@/api/page_data';
export default {
  name: 'HomeApplyForm',
  data() {
    return {
      form_data: {
        name: '',
        phone: '',
        line_id: '',
        time: '',
        question: '',
        money: '',
      },
      errors: [],
    };
  },
  methods: {
    Validate() {
      this.errors = [];
      this.form_data.name == '' ? this.errors.push('name') : '';
      !validPhone(this.form_data.phone) ? this.errors.push('phone') : '';
      this.form_data.line_id == '' ? this.errors.push('line_id') : '';
      this.form_data.time == '' ? this.errors.push('time') : '';
      this.form_data.question == '' ? this.errors.push('question') : '';
      this.form_data.money == '' ? this.errors.push('money') : '';

      this.errors.length <= 0 ? this.SendData() : '';
    },
    GetError(key) {
      return this.errors.indexOf(key) != -1;
    },
    SendData() {
      let tmp_send_data = Object.assign({}, this.form_data);
      tmp_send_data.comment = `Line ID：${tmp_send_data.line_id}<br/>`;
      tmp_send_data.comment += `方便聯絡時間：${tmp_send_data.time}<br/>`;
      tmp_send_data.comment += `諮詢項目：${tmp_send_data.question}<br/>`;
      tmp_send_data.comment += `需求資金：${tmp_send_data.money}<br/>`;
      postContact(tmp_send_data).then(() => {
        this.$store.commit('SetDialog', {
          status: true,
          content: '訊息已送出，我們會盡快與您聯絡！',
        });
      });
    },
  },
};
</script>
