import { gsap } from '@/gsap/gsap_loader';

export class section_animation {
  constructor(el) {
    this.el = el;
    this.title = el.querySelectorAll('[data-section-title]');
    this.sub_title = el.querySelectorAll('[data-section-subtitle]');
    this.button = el.querySelectorAll('[data-section-button]');

    this.timeline = null;
    this.setup();
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        scroller: '#app',
        trigger: this.el,
        start: 'top 70%',
        toggleActions: 'restart play play reverse',
      },
    });

    this.timeline
      .fromTo(
        this.sub_title,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
        0
      )
      .fromTo(
        this.title,
        {
          opacity: 0,
          y: '50%',
        },
        {
          opacity: 1,
          y: '0%',
          delay: 0.3,
        },
        0
      )
      .fromTo(
        this.button,
        {
          opacity: 0,
          x: '-50%',
        },
        {
          opacity: 1,
          x: '0%',
          delay: 0.3,
        },
        0
      );
  }
}
