<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full max-w-screen-xl mx-auto"
  >
    <div
      v-if="service_list != null"
      class="w-full container_special pt-28 md:pt-40 pb-14 md:pb-20"
    >
      <header
        class="flex flex-col w-full max-w-screen-xl mb-10 px-7 md:px-10 lg:px-14 md:flex-row md:items-end md:justify-between"
      >
        <div>
          <small class="block overflow-hidden">
            <span
              data-section-subtitle
              class="block text-base font-black md:text-xl font-red_hat text-primary"
              >Service</span
            >
          </small>
          <h2 class="mb-5 overflow-hidden md:mb-0">
            <span
              data-section-title
              class="block text-3xl font-black md:text-4xl text-main_black"
            >
              服務項目
            </span>
          </h2>
        </div>
        <router-link
          data-section-button
          to="/service_list"
          class="w-44 h-fit md:w-60 px-6 md:px-10 py-3 md:py-4 flex items-center justify-between text-primary border-[1.5px] md:border-2 border-primary hover:text-white hover:bg-primary transition-colors duration-500"
        >
          <span class="inline-block mr-5 text-sm font-bold">了解更多</span>
          <span class="text-sm font-bold icon-arrow"></span>
        </router-link>
      </header>

      <div class="relative w-full">
        <Skeleton v-show="!block_ready" />
        <ul
          :class="
            block_ready
              ? 'relative z-10'
              : 'absolute top-0 left-0 z-0 opacity-0'
          "
          class="flex flex-wrap w-full md:px-10 lg:px-14 xl:pr-0"
        >
          <li
            v-for="(item, item_index) in service_data"
            :key="`service_${item_index}`"
            class="w-full xl:w-1/2 mb-7 md:mb-3 xl:mb-10 aspect-[4/5] xl:max-h-[330px] md:aspect-[2/1] relative flex flex-col md:flex-row"
          >
            <router-link
              :to="`/service/${item.GoodsID}`"
              class="w-full overflow-hidden aspect-square md:order-1"
            >
              <img
                data-parallax-image
                :src="$ImageUrl(item.Image2)"
                :alt="`服務項目-${item.Title}`"
                class="w-full h-[150%] object-cover hidden md:block"
                data-parallax-start="0%"
                data-parallax-end="-30%"
              />
              <img
                :src="$ImageUrl(item.Image2)"
                :alt="`服務項目-${item.Title}`"
                class="block object-cover w-full h-full md:hidden"
              />
            </router-link>
            <div
              class="w-full md:w-[60%] h-fit md:h-full absolute md:relative z-10 bottom-0"
            >
              <div
                :style="`background-image: url('${$ImageUrl(item.Image1)}');`"
                class="relative z-10 pt-5 overflow-hidden bg-center bg-cover md:h-full md:pt-7 mx-7 md:mx-0"
              >
                <div
                  data-service-item
                  class="relative z-10 flex flex-col md:justify-between md:h-full"
                >
                  <h3
                    class="relative z-10 w-full px-5 mb-5 text-2xl font-bold md:px-7 md:text-3xl text-main_white"
                  >
                    {{ item.Title }}
                  </h3>
                  <div class="relative z-10 w-full">
                    <ul class="w-full mb-5">
                      <li
                        v-for="(tag, tag_index) in item.Tags"
                        :key="`tag_${item_index}_${tag_index}`"
                        class="flex items-center w-full px-5 mb-2 md:px-7"
                      >
                        <span class="inline-block w-5 h-5 mr-2">
                          <img
                            :src="GetIcon(tag.Icon).image"
                            :alt="GetIcon(tag.Icon).text"
                            class="block w-full"
                          />
                        </span>
                        <span class="text-sm font-medium text-main_white">{{
                          tag.Text
                        }}</span>
                      </li>
                    </ul>
                    <router-link
                      :to="`/service/${item.GoodsID}`"
                      class="relative z-10 flex items-center justify-between px-6 py-3 w-fit md:px-7 bg-primary_dark hover:bg-main_hover btn_hover"
                    >
                      <span
                        class="inline-block mr-2 text-sm font-bold font-red_hat text-main_white"
                        >More</span
                      >
                      <span
                        class="text-sm font-bold icon-arrow text-main_white"
                      ></span>
                    </router-link>
                  </div>
                </div>

                <span
                  class="absolute top-0 bottom-0 left-0 right-0 w-full h-full z-2 bg-primary bg-opacity-95"
                ></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import Skeleton from '@/components/home/Skeleton/ServiceList.vue';
import { section_animation } from '@/gsap/home/section';
import { service_animation } from '@/gsap/home/service';
import { parallax_image } from '@/gsap/home/parallax_image';
import service_icon_data from '@/assets/data/service_icon.json';
export default {
  name: 'HomeServiceList',
  components: {
    Skeleton,
  },
  data() {
    return {
      section_animation: null,
      service_animation: null,
      parallax_image_animation: null,
      block_ready: false,
    };
  },
  methods: {
    GetIcon(key) {
      return service_icon_data.filter((item) => item.text == key)[0];
    },
    SetGsap() {
      this.block_ready = true;
      this.$nextTick(() => {
        this.section_animation = new section_animation(this.$refs.MainContent);
        this.service_animation = new service_animation(this.$refs.MainContent);
        this.parallax_image_animation = new parallax_image(
          this.$refs.MainContent
        );
      });
    },
  },
  computed: {
    service_list() {
      return this.$store.state.product_data;
    },
    service_data() {
      if (this.service_list == null) {
        return [];
      }
      return this.$store.state.product_data.slice(0, 4);
    },
  },
};
</script>
