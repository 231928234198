<template>
  <div
    id="MainLoading"
    ref="MainContent"
    class="fixed z-40 flex items-center justify-center w-full h-full transform left-full"
  >
    <div data-loading-box ref="content" class="relative z-10">
      <p
        data-loading-title
        class="mb-5 text-4xl font-black text-primary title sm:text-4xl text-main-blue-color"
      >
        LOADING
      </p>
      <span class="loader text-primary"></span>
    </div>
    <div
      data-loading-bg
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-white bg-opacity-80"
    ></div>
  </div>
</template>

<style scoped>
.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
}
</style>

<script>
import { loading_animation } from '@/gsap/loading';
export default {
  name: 'MainLoading',
  data() {
    return {
      loading_animation: null,
    };
  },
  watch: {
    loading(new_val, old_val) {
      if (old_val == 0 && new_val != 0) {
        //open
        this.loading_animation.open();
        document.querySelector('body').classList.add('lock');
      } else if (old_val != 0 && new_val == 0) {
        //close
        this.loading_animation.close();
        document.querySelector('body').classList.remove('lock');
      }
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  mounted() {
    this.loading_animation = new loading_animation(this.$refs.MainContent);
    // this.loading_animation.open();
  },
};
</script>
