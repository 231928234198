<template>
  <main
    id="Home"
    data-scroll-section
    class="relative z-10 pt-[116px] md:pt-[148px]"
  >
    <h1 class="hidden">鉅祥首頁</h1>
    <Carousel ref="Carousel" />
    <Features ref="Features" />
    <ApplyForm ref="ApplyForm" />
    <ServiceList ref="ServiceList" />
    <Process ref="Process" />
    <About ref="About" />
    <Promise ref="Promise" />
    <CaseList ref="CaseList" />
  </main>
</template>

<script>
import Features from "@/components/home/Features.vue";
import Carousel from "@/components/home/Carousel.vue";
import ApplyForm from "@/components/home/ApplyForm.vue";
import ServiceList from "@/components/home/ServiceList.vue";
import Process from "@/components/home/Process.vue";
import About from "@/components/home/About.vue";
import Promise from "@/components/home/Promise.vue";
import CaseList from "@/components/home/CaseList.vue";
import { mapGetters, mapState } from "vuex";
export default {
  name: "HomeView",
  components: {
    Carousel,
    ApplyForm,
    ServiceList,
    Process,
    About,
    Promise,
    CaseList,
    Features,
  },
  data() {
    return {
      meta_data: null,
    };
  },
  methods: {
    PageInit() {
      this.meta_data = this.$GetMetaData(
        this.$GetColumn("meta_title_home"),
        this.$GetColumn("meta_content_home"),
        this.$GetColumn("meta_image_home") != ""
          ? this.$ImageUrl(this.$GetColumn("meta_image_home"))
          : ""
      );
      this.$emit("set-breadcrumb", [
        {
          title: "首頁",
          link: "/",
        },
      ]);
      this.$nextTick(() => {
        this.$emit("load-image");
      });
    },
    SetGsap() {
      this.$PageReady(this.meta_data.title);
      this.$refs.Carousel.SetGsap();
      this.$refs.CaseList.SetGsap();
      this.$refs.ServiceList.SetGsap();
      this.$refs.Process.SetGsap();
      this.$refs.About.SetGsap();
      this.$refs.Promise.SetGsap();
    },
  },
  watch: {
    data_load_finish() {
      this.data_load_finish ? this.PageInit() : "";
    },
    image_loaded() {
      this.image_loaded ? this.SetGsap() : "";
    },
  },
  computed: {
    ...mapState(["image_loaded"]),
    ...mapGetters(["data_load_finish"]),
  },
  created() {
    this.data_load_finish ? this.PageInit() : "";
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
