<template>
  <section
    ref="MainContent"
    class="fixed top-0 z-30 hidden w-full h-full transform xl:block mt-28 left-full"
  >
    <div
      data-dialog-box
      class="relative z-10 w-full py-10 bg-primary bg-opacity-95"
    >
      <div
        class="relative z-10 w-full max-w-screen-xl mx-auto transition-all duration-700 transform service_dropdown_content"
      >
        <ul class="relative z-10 flex flex-wrap md:-mx-2">
          <li
            v-for="(item, item_index) in service_list_data"
            :key="`service_${item_index}`"
            :class="(item_index + 1) % 3 == 0 ? '' : 'md:border-r'"
            class="relative z-10 w-full pb-2 mb-2 border-b md:w-1/2 xl:w-1/3 md:pb-0 md:px-2 md:mb-4 md:border-b-0 border-primary_dark"
          >
            <router-link
              @click.native="$emit('close-action')"
              :to="`/service/${item.GoodsID}`"
              class="service_link w-full aspect-[2/1] block overflow-hidden"
            >
              <img
                :src="$ImageUrl(item.Image1)"
                :alt="`服務項目-${item.Title}`"
                class="block object-cover w-full h-full"
              />
            </router-link>
            <span
              class="absolute right-0 z-10 block px-6 py-2 text-sm font-bold bottom-2 md:bottom-0 md:right-2 xl:text-base text-main_white bg-primary_dark"
              >{{ item.Title }}</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div
      data-dialog-bg
      @click="$emit('close-action')"
      class="absolute top-0 left-0 z-0 w-full h-full bg-white bg-opacity-70"
    ></div>
  </section>
</template>

<script>
import { dropdown_animation } from '@/gsap/header_dropdown';
export default {
  name: 'MainHeaderDropdown',
  props: {
    dropdown_open: {
      type: Boolean,
    },
  },
  data() {
    return {
      dropdown_animation: null,
    };
  },
  watch: {
    dropdown_open() {
      this.dropdown_open
        ? this.dropdown_animation.open()
        : this.dropdown_animation.close();
    },
  },
  computed: {
    service_list_data() {
      return this.$store.state.product_data;
    },
  },
  mounted() {
    this.dropdown_animation = new dropdown_animation(this.$refs.MainContent);
  },
};
</script>
