<template>
  <div
    ref="MainContent"
    class="fixed top-0 z-40 flex items-center justify-center w-full h-full dialog px-7 md:px-10 lg:px-14 left-full"
  >
    <div
      data-dialog-box
      class="relative z-10 flex flex-col items-center transform translate-y-20 rounded opacity-0 dialog_content w-fit p-7 sm:p-10 md:p-14 bg-bg_white"
    >
      <p
        class="w-full mb-5 text-sm font-bold text-justify md:mb-10 md:text-base sm:text-center text-primary"
      >
        {{ content }}
      </p>
      <button
        type="button"
        @click="Close"
        class="px-6 py-2 text-sm font-medium text-center dialog_close_btn w-fit md:text-base text-main_white bg-primary_dark hover:bg-main_hover btn_hover"
      >
        關閉
      </button>
    </div>
    <span
      data-dialog-bg
      class="absolute top-0 left-0 z-0 w-full h-full bg-main_black bg-opacity-80"
    ></span>
  </div>
</template>

<script>
import { dialog_animation } from '@/gsap/dialog';
import { mapState } from 'vuex';
export default {
  name: 'MainDialog',
  data() {
    return {
      dialog_animation: null,
    };
  },
  methods: {
    Close() {
      this.$store.commit('SetDialog', { status: false, content: this.content });
    },
  },
  watch: {
    status() {
      this.status
        ? this.dialog_animation.open()
        : this.dialog_animation.close();
    },
  },
  computed: {
    ...mapState({
      content: (state) => state.main_dialog.content,
      status: (state) => state.main_dialog.status,
    }),
  },
  mounted() {
    this.dialog_animation = new dialog_animation(this.$refs.MainContent);
  },
};
</script>
