import { render, staticRenderFns } from "./MainLoading.vue?vue&type=template&id=ae1fc612&scoped=true&"
import script from "./MainLoading.vue?vue&type=script&lang=js&"
export * from "./MainLoading.vue?vue&type=script&lang=js&"
import style0 from "./MainLoading.vue?vue&type=style&index=0&id=ae1fc612&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae1fc612",
  null
  
)

export default component.exports