<template>
  <section
    id="MainMenu"
    :class="!menu_open ? '-translate-x-0' : '-translate-x-full'"
    class="fixed top-0 z-0 w-full h-full overflow-hidden transition-all duration-500 transform main_menu xl:hidden left-full bg-primary bg-opacity-95"
  >
    <div
      class="relative w-full h-full max-w-screen-xl mx-auto overflow-y-auto transition-all duration-500 transform main_menu_content pt-[116px] md:pt-[148px]"
    >
      <div class="w-full px-7 md:px-28">
        <ul id="MenuList" class="w-full pt-7 md:pt-14 pb-28">
          <li class="w-full pb-2 mb-5 border-b md:mb-10 border-primary_dark">
            <router-link
              @click.native="$emit('set-open', false)"
              to="/about"
              class="w-full"
            >
              <p class="w-full text-xl font-bold md:text-2xl text-main_white">
                <small
                  class="block text-sm font-black font-red_hat text-primary_dark"
                  >About</small
                >關於我們
              </p>
            </router-link>
          </li>
          <li
            class="w-full pb-2 mb-5 border-b main_menu_item md:mb-10 border-primary_dark"
          >
            <div
              class="flex items-end justify-between w-full mb-3 menu_item_title"
            >
              <router-link
                to="/service_list"
                @click.native="$emit('set-open', false)"
                class="inline-block w-full mr-5"
              >
                <p
                  class="w-full text-xl font-bold text-left md:text-2xl text-main_white"
                >
                  <small
                    class="block text-sm font-black font-red_hat text-primary_dark"
                    >Service</small
                  >服務項目
                </p>
              </router-link>
              <span
                class="flex items-center justify-center flex-shrink-0 w-10 h-10 text-base font-medium text-center icon-chevron_down text-main_white"
              ></span>
            </div>

            <ul
              class="flex flex-wrap w-full border-t border-l menu_item_content border-primary_dark"
            >
              <li
                v-for="(item, item_index) in product_data"
                :key="`service_${item_index}`"
                class="w-1/2 py-4 border-b border-r md:w-1/3 border-primary_dark"
              >
                <router-link
                  @click.native="$emit('set-open', false)"
                  :to="`/service/${item.GoodsID}`"
                  class="block w-full text-sm font-medium text-center text-main_white"
                  >{{ item.Title }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="w-full pb-2 mb-5 border-b md:mb-10 border-primary_dark">
            <router-link
              to="/case_list"
              @click.native="$emit('set-open', false)"
              class="w-full"
            >
              <p class="w-full text-xl font-bold md:text-2xl text-main_white">
                <small
                  class="block text-sm font-black font-red_hat text-primary_dark"
                  >Blog</small
                >鉅祥部落格
              </p>
            </router-link>
          </li>
          <li
            class="w-full pb-2 mb-5 border-b main_menu_item md:mb-10 border-primary_dark"
          >
            <div
              class="flex items-end justify-between w-full mb-3 menu_item_title"
            >
              <router-link
                to="/question"
                @click.native="$emit('set-open', false)"
                class="inline-block w-full mr-5"
              >
                <p
                  class="w-full text-xl font-bold text-left md:text-2xl text-main_white"
                >
                  <small
                    class="block text-sm font-black font-red_hat text-primary_dark"
                    >FAQ</small
                  >常見問題
                </p>
              </router-link>
              <span
                class="flex items-center justify-center flex-shrink-0 w-10 h-10 text-base font-medium text-center icon-chevron_down text-main_white"
              ></span>
            </div>

            <ul
              class="flex flex-wrap w-full border-t border-l menu_item_content border-primary_dark"
            >
              <li
                v-for="(item, item_index) in question_category_data"
                :key="`question_${item_index}`"
                class="w-1/2 py-4 border-b border-r md:w-1/3 border-primary_dark"
              >
                <router-link
                  :to="`/question#${item.Title}`"
                  @click.native="$emit('set-open', false)"
                  class="block w-full text-sm font-medium text-center text-main_white"
                  >{{ item.Title }}</router-link
                >
              </li>
            </ul>
          </li>
          <li class="w-full pb-2 mb-5 border-b md:mb-10 border-primary_dark">
            <router-link
              to="/contact"
              @click.native="$emit('set-open', false)"
              class="inline-block w-full mb-3"
            >
              <p
                class="w-full text-xl font-bold text-left md:text-2xl text-main_white"
              >
                <small
                  class="block text-sm font-black font-red_hat text-primary_dark"
                  >Contact</small
                >聯絡我們
              </p>
            </router-link>
            <ul class="flex flex-col w-full md:flex-row">
              <li class="w-full mb-2 md:mb-0 md:mr-2">
                <a
                  href="https://lin.ee/6RDE1Wl"
                  target="_blank"
                  class="w-full px-10 py-4 flex justify-between items-center text-primary bg-[#cccccc] hover:bg-main_white btn_hover"
                >
                  <span class="text-sm font-bold">加入官方LINE</span>
                  <span class="text-sm font-bold icon-arrow"></span>
                </a>
              </li>
              <li class="w-full mb-2 md:mb-0">
                <router-link
                  to="/contact"
                  @click.native="$emit('set-open', false)"
                  class="w-full px-10 py-4 flex justify-between items-center text-primary bg-[#cccccc] hover:bg-main_white btn_hover"
                >
                  <span class="text-sm font-bold">立即填寫表單</span>
                  <span class="text-sm font-bold icon-arrow"></span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
// import service_detail_data from '@/assets/data/service_detail.json';
// import question_category_data from '@/assets/data/question_category.json';
import { mapState } from "vuex";
export default {
  name: "MainMenu",
  props: {
    menu_open: {
      type: Boolean,
    },
  },
  data() {
    return {
      // service_detail_data: service_detail_data,
      // question_category_data: question_category_data,
    };
  },
  computed: {
    ...mapState(["product_data", "question_category_data"]),
  },
};
</script>
