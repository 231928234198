<template>
  <div class="w-full aspect-[4/5] xl:aspect-[2/1] xl:pr-14 flex relative z-10">
    <div class="w-full xl:w-[60%] h-full xl:mr-20 skeleton"></div>
    <div class="w-[40%] h-full hidden xl:block skeleton"></div>
    <div
      class="w-full xl:w-[40%] xl:h-full md:px-10 lg:px-14 xl:px-0 absolute z-[15] bottom-0 xl:right-14 transform translate-y-40 md:translate-y-20 xl:translate-y-0"
    >
      <div class="flex flex-col w-full xl:h-full p-7 md:p-10 xl:justify-center">
        <header class="w-full mb-10">
          <span class="block w-16 h-4 mb-2 bg-black">
            <span class="block w-full h-full opacity-80 skeleton"></span>
          </span>
          <span class="block w-2/3 h-10 bg-black">
            <span class="block w-full h-full opacity-80 skeleton"></span>
          </span>
        </header>
        <span class="block w-full h-8 mb-3 bg-black">
          <span class="block w-full h-full opacity-80 skeleton"></span>
        </span>
        <span class="block w-full h-24 mb-10 bg-black">
          <span class="block w-full h-full opacity-80 skeleton"></span>
        </span>
        <span class="block w-1/2 h-12 bg-black">
          <span class="block w-full h-full opacity-80 skeleton"></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeAboutSekeleton',
};
</script>
