<template>
  <ul class="relative z-10 flex flex-wrap w-full md:px-10 lg:px-14 xl:pr-0">
    <li
      v-for="item in 4"
      :key="`skeleton_${item}`"
      class="w-full xl:w-1/2 mb-7 md:mb-3 xl:mb-10 aspect-[4/5] xl:max-h-[330px] md:aspect-[2/1] relative flex flex-col md:flex-row"
    >
      <div class="w-full aspect-square md:order-1 skeleton"></div>
      <div
        class="w-full md:w-[60%] h-fit md:h-full absolute md:relative z-10 bottom-0"
      >
        <div
          class="relative z-10 pt-5 overflow-hidden bg-center bg-cover md:h-full md:pt-7 mx-7 md:mx-0"
        >
          <div class="relative z-10 flex flex-col md:justify-between md:h-full">
            <div class="px-5 md:px-7">
              <span
                class="relative z-10 block w-32 h-8 mb-5 font-bold skeleton opacity-10"
              ></span>
            </div>
            <div class="relative z-10 w-full">
              <ul class="w-full px-5 mb-5 md:px-7">
                <li class="flex items-center w-full mb-2">
                  <span
                    class="relative z-10 inline w-24 h-4 font-bold skeleton opacity-10"
                  ></span>
                </li>
                <li class="flex items-center w-full mb-2">
                  <span
                    class="relative z-10 inline h-4 font-bold w-28 skeleton opacity-10"
                  ></span>
                </li>
                <li class="flex items-center w-full mb-2">
                  <span
                    class="relative z-10 inline w-32 h-4 font-bold skeleton opacity-10"
                  ></span>
                </li>
              </ul>
              <span
                class="relative z-10 block w-32 h-10 px-6 py-3 md:px-7 bg-primary_dark"
              >
              </span>
            </div>
          </div>

          <span
            class="absolute top-0 bottom-0 left-0 right-0 w-full h-full z-2 bg-primary bg-opacity-95"
          ></span>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HomeServiceListSkeleton',
};
</script>
