<template>
  <footer ref="MainContent" data-scroll-section>
    <!-- ===================== Notice ===================== -->
    <section class="relative z-10 w-full bg-bg_white">
      <div
        class="relative z-10 w-full max-w-screen-xl mx-auto px-7 md:px-10 lg:px-14 py-28 md:py-40"
      >
        <div
          class="w-full px-7 md:px-10 pt-10 md:pt-14 pb-7 md:pb-10 relative z-10 border-[1.5px] border-primary rounded-sm"
        >
          <header
            class="absolute top-0 z-10 flex items-center px-2 transform -translate-y-1/2 w-fit left-5 bg-bg_white"
          >
            <span class="block mr-2 w-7 h-7 md:w-10 md:h-10">
              <img
                src="/img/notice_icon.webp"
                alt="防詐騙提醒"
                class="block w-full m-auto"
              />
            </span>
            <p class="text-2xl font-black md:text-3xl text-primary">
              防詐騙提醒
            </p>
          </header>
          <ul
            class="w-full text-sm text-justify list-disc list-inside md:text-base text-primary"
          >
            <li>
              {{ $GetColumn('warning') }}
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- ================= Contact+Footer ================= -->
    <section class="relative z-10 w-full bg-bg_white">
      <div class="relative z-10 w-full max-w-screen-xl mx-auto">
        <div
          class="relative z-10 w-full overflow-hidden container_special pl-7 md:pl-10 lg:pl-14"
        >
          <div
            class="relative z-10 flex w-full overflow-hidden contact_content xl:justify-start"
          >
            <div
              class="w-full relative z-10 max-w-screen-xl px-7 md:px-20 xl:px-28 aspect-square md:max-h-[440px] xl:aspect-[1/2] flex flex-col xl:flex-row justify-center xl:items-center xl:justify-start"
            >
              <header class="relative z-10 w-full mb-10 xl:shrink">
                <h4 class="mb-5 text-2xl font-black md:text-3xl text-primary">
                  <small
                    class="block text-base font-black text-justify md:text-xl font-red_hat text-primary"
                    >Contact Us</small
                  >
                  立即免費諮詢
                </h4>
                <p
                  class="w-full text-sm text-justify md:text-base text-primary_dark"
                >
                  豐富透明、誠信便捷、真心為您著想，我們是您最好的選擇。
                </p>
              </header>
              <router-link
                to="/contact"
                class="h-fit w-60 px-6 md:px-10 py-3 md:py-4 flex items-center justify-between shrink-0 relative z-10 text-primary border-[1.5px] md:border-2 border-primary hover:bg-primary hover:text-white transition-colors duration-500"
              >
                <span class="inline-block mr-5 text-sm font-bold"
                  >立即填寫表單</span
                >
                <span class="text-sm font-bold icon-arrow"></span>
              </router-link>
            </div>
            <div
              class="absolute top-0 left-0 right-0 bottom-0 z-[1] bg-white opacity-60"
            ></div>
            <img
              data-parallax-image
              :src="$ImageUrl($GetColumn('contact_pc_image'))"
              alt="立即免費諮詢"
              class="absolute top-0 left-0 w-full h-[200%] z-0 object-cover block"
            />
          </div>
        </div>
      </div>
      <span class="absolute bottom-0 w-full h-2/4 bg-primary"></span>
    </section>
    <!-- ================================================== -->
    <section class="relative z-10 w-full footer_bg">
      <div
        class="relative z-10 w-full max-w-screen-xl pb-20 mx-auto px-7 md:px-10 lg:px-14 pt-14 md:pt-20 md:pb-28"
      >
        <div
          class="flex flex-col w-full pl-7 md:pl-10 lg:pl-20 xl:pl-28 md:flex-row md:items-baseline md:justify-between"
        >
          <header class="w-full md:w-1/2">
            <div class="w-40 mb-10 md:w-48">
              <img
                src="/img/footer_logo.webp"
                :alt="$GetColumn('company_name')"
                class="block w-full"
              />
            </div>
            <ul class="w-full mb-10">
              <li class="flex w-full mb-3">
                <span
                  class="inline-block mr-2 text-sm font-bold text-justify w-fit md:text-base text-main_white shrink-0"
                  >公司地址</span
                >
                <span
                  class="inline-block w-full text-sm font-medium text-justify md:text-base text-main_white shrink-1"
                  >{{ $GetColumn('company_address') }}</span
                >
              </li>
              <li class="flex w-full">
                <span
                  class="inline-block mr-2 text-sm font-bold text-justify w-fit md:text-base text-main_white shrink-0"
                  >聯絡電話</span
                >
                <span
                  class="inline-block w-full text-sm font-medium text-justify md:text-base font-red_hat text-main_white shrink-1"
                  >{{ $GetColumn('company_phone') }}</span
                >
              </li>
            </ul>
          </header>
          <a
            :href="$GetColumn('company_line')"
            target="_blank"
            class="h-fit w-60 px-6 md:px-10 py-3 md:py-4 flex items-center justify-between shrink-0 text-main_white border-[1.5px] md:border-2 border-main_white hover:bg-main_white hover:text-primary btn_hover"
          >
            <span class="inline-block mr-5 text-sm font-bold"
              >加入官方LINE</span
            >
            <span class="text-sm font-bold icon-arrow"></span>
          </a>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import { parallax_image } from '@/gsap/home/parallax_image';
import { mapState, mapGetters } from 'vuex';
export default {
  name: 'MainFooter',
  data() {
    return {
      parallax_image: null,
    };
  },
  methods: {
    PageInit() {
      this.$emit('load-image');
    },
    SetGsap() {
      this.parallax_image = new parallax_image(this.$refs.MainContent);
    },
  },
  watch: {
    data_load_finish() {
      this.data_load_finish ? this.PageInit() : '';
    },
    image_loaded() {
      this.image_loaded ? this.SetGsap() : '';
    },
  },
  computed: {
    ...mapState(['image_loaded']),
    ...mapGetters(['data_load_finish']),
  },
};
</script>
