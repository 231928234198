<template>
  <section ref="MainContent" class="relative z-10 w-full">
    <div
      v-if="case_list != null"
      class="relative z-10 w-full container_special pt-28 md:pt-40"
    >
      <header
        class="flex flex-col w-full max-w-screen-xl mx-auto mb-10 px-7 md:px-10 lg:px-14 md:flex-row md:items-end md:justify-between"
      >
        <div>
          <small class="block overflow-hidden">
            <span
              data-section-subtitle
              class="block text-base font-black md:text-xl font-red_hat text-primary"
              >Blog</span
            >
          </small>
          <h2 class="block overflow-hidden">
            <span
              data-section-title
              class="block mb-5 text-3xl font-black md:mb-0 md:text-4xl text-main_black"
              >鉅祥部落格</span
            >
          </h2>
        </div>
        <router-link
          data-section-button
          to="/case_list"
          class="w-44 h-fit md:w-60 px-6 md:px-10 py-3 md:py-4 flex items-center justify-between text-primary border-[1.5px] md:border-2 border-primary hover:text-white transition-colors duration-500 hover:bg-primary btn_hover"
        >
          <span class="inline-block mr-5 text-sm font-bold">了解更多</span>
          <span class="text-sm font-bold icon-arrow"></span>
        </router-link>
      </header>

      <div class="relative z-10 w-full xl:pr-14">
        <div
          class="relative z-10 w-full overflow-hidden pt-14 pr-7 md:pt-20 md:pr-20 xl:pr-60"
        >
          <div
            data-button-group
            class="w-full md:w-[70%] h-full absolute z-0 top-0 right-0 bg-primary"
          >
            <div
              class="absolute flex w-fit z-5 left-7 md:left-0 xl:right-0 xl:left-auto xl:bottom-0"
            >
              <button
                @click="Prev"
                class="icon-arrow w-10 md:w-14 h-10 md:h-14 flex items-center justify-center text-xs text-center text-main_white bg-primary_dark border-l-[0.5px] border-primary transform -scale-x-100 hover:bg-main_hover btn_hover"
              ></button>
              <button
                @click="Next"
                class="flex items-center justify-center w-10 h-10 text-xs text-center icon-arrow md:w-14 md:h-14 text-main_white bg-primary_dark hover:bg-main_hover btn_hover"
              ></button>
            </div>
          </div>
          <div class="relative z-10 w-full overflow-hidden">
            <Skeleton v-show="!block_ready" />
            <ul
              :class="
                block_ready
                  ? 'relative z-10'
                  : 'absolute top-0 left-0 z-0 opacity-0'
              "
              class="flex justify-end w-full h-fit"
            >
              <li
                data-carousel-item
                class="case_card w-[330px] sm:w-[440px] flex-shrink-0 relative z-10 cursor-pointer"
                v-for="(item, item_index) in case_data"
                :key="`case_${item_index}`"
              >
                <router-link
                  :to="`/case/${item.UrlTitle}`"
                  class="relative block w-full h-full"
                >
                  <div
                    class="relative z-10 w-full overflow-hidden aspect-square"
                  >
                    <div
                      class="absolute top-0 bottom-0 left-0 right-0 z-10 bg-black bg-opacity-30"
                    ></div>
                    <img
                      :src="$ImageUrl(item.Image1)"
                      :alt="item.Title"
                      class="relative z-0 block object-cover w-full h-full transition-all duration-700 transform case_card_img brightness-75"
                    />
                  </div>
                  <div
                    @mouseenter="hover = item_index"
                    @mouseleave="hover = -1"
                    class="absolute top-0 bottom-0 left-0 right-0 z-10 flex flex-col items-start justify-end transition-all duration-700 transform p-7"
                  >
                    <h3 class="w-full mb-3 text-xl font-black text-main_white">
                      {{ item.Title }}
                    </h3>
                    <div
                      :class="
                        hover == item_index
                          ? 'max-h-full duration-700'
                          : 'max-h-[40px] duration-300'
                      "
                      class="w-full mb-3 overflow-hidden text-sm font-medium text-justify transition-all transform text-main_white"
                      v-html="GetContent(item.Content)"
                    ></div>
                    <router-link
                      :to="`/case/${item.UrlTitle}`"
                      class="px-3 py-1 text-sm font-bold border w-fit text-main_white hover:opacity-80 btn_hover"
                    >
                      查看全部
                    </router-link>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <span
          class="absolute top-0 bottom-0 hidden transform translate-x-full case_bg_el xl:block right-14 bg-primary"
        ></span>
      </div>
    </div>
  </section>
</template>

<script>
import Skeleton from "@/components/home/Skeleton/CaseList.vue";
import { carousel_animation } from "@/gsap/home/case_list";
import { section_animation } from "@/gsap/home/section";
export default {
  components: {
    Skeleton,
  },
  data() {
    return {
      carousel_animation: null,
      section_animation: null,
      hover: -1,
      block_ready: false,
    };
  },
  methods: {
    GetContent(val) {
      return val.replace(/(<([^>]+)>)/gi, "").slice(0, 50) + "...";
    },
    SetGsap() {
      this.block_ready = true;
      this.$nextTick(() => {
        this.carousel_animation = new carousel_animation(
          this.$refs.MainContent
        );
        this.section_animation = new section_animation(this.$refs.MainContent);
      });
    },
    Next() {
      this.carousel_animation.changeCarousel(1);
    },
    Prev() {
      this.carousel_animation.changeCarousel(-1);
    },
  },
  computed: {
    case_list() {
      return this.$store.state.news_data;
    },
    case_data() {
      return this.case_list.slice(0, 10);
    },
  },
};
</script>
