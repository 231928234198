<template>
  <section class="relative z-10 block w-full mb-20">
    <div class="relative w-full max-w-screen-xl px-10 mx-auto lg:px-0">
      <ol class="flex flex-wrap items-center w-full">
        <li
          v-for="item_index in 6"
          :key="`feature_${item_index}`"
          :class="GetBorderClass(item_index)"
          class="w-1/2 px-5 py-5 mb-10 text-center md:w-1/3 lg:w-1/6 lg:mb-0 border-primary"
        >
          <div class="w-full mb-1">
            <img
              :alt="
                $GetColumn('company_name') +
                ' ' +
                $GetColumn(`feature_${item_index}_content`)
              "
              :src="$ImageUrl($GetColumn(`feature_${item_index}_image`))"
              class="block w-full"
            />
          </div>
          <p class="font-black text-primary">
            {{ $GetColumn(`feature_${item_index}_content`) }}
          </p>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomeFeatures',
  data() {
    return {
      features: [
        {
          title: '核貸才收費',
          image: '/img/features/icon_money.webp',
        },
        {
          title: '無事前收費',
          image: '/img/features/icon_money-1.webp',
        },
        {
          title: '工作3個月即可辦',
          image: '/img/features/icon_money-2.webp',
        },
        {
          title: '最快24小時撥款',
          image: '/img/features/icon_money-3.webp',
        },
        {
          title: '24小時免費諮詢',
          image: '/img/features/icon_money-4.webp',
        },
        {
          title: '不收正本資料',
          image: '/img/features/icon_money-5.webp',
        },
      ],
    };
  },
  methods: {
    GetBorderClass(index) {
      let class_text = '';
      index != 6
        ? (class_text += ' lg:border-r')
        : (class_text += ' lg:border-r-0');
      index % 3 != 0
        ? (class_text += ' md:border-r')
        : (class_text += ' md:border-r-0');
      index % 2 != 0
        ? (class_text += ' border-r')
        : (class_text += ' border-r-0');
      return class_text;
    },
  },
};
</script>
