import { gsap } from '@/gsap/gsap_loader';

export class carousel_animation {
  constructor(el) {
    this.el = el;
    this.carousel_item = el.querySelectorAll('[data-carousel-item]');
    this.button_group = el.querySelectorAll('[data-button-group]');
    this.carousel_timeline = null;
    this.timeline = null;
    this.active_index = 0;
    this.setup();
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        scroller: '#app',
        trigger: this.el,
        start: 'top 70%',
        toggleActions: 'restart play play reverse',
      },
    });
    this.timeline
      .fromTo(
        this.carousel_item,
        {
          y: '100%',
        },
        {
          y: '0%',
          stagger: -0.1,
        },
        'same'
      )
      .fromTo(
        this.button_group,
        {
          y: '100%',
        },
        {
          y: '0%',
        },
        'same'
      );
  }

  changeCarousel(action) {
    this.carousel_timeline != null ? this.carousel_timeline.kill() : '';
    this.carousel_timeline = gsap.timeline();

    if (action == 1) {
      this.active_index =
        this.active_index == this.carousel_item.length - 1
          ? 0
          : this.active_index + 1;
    } else {
      this.active_index =
        this.active_index == 0
          ? this.carousel_item.length - 1
          : this.active_index - 1;
    }

    this.carousel_timeline.to(this.carousel_item, {
      x: `${this.active_index * 100}%`,
    });
  }
}
