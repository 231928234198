<template>
  <div id="container">
    <MainHeader />
    <MainDialog />
    <MainLoading />
    <BreadCrumb :path="bread_crumb_path" />
    <div id="app">
      <router-view
        ref="RouterView"
        @load-image="LoadImage"
        @scroll-top="ScrollToTop"
        @update-scroll="UpdateScroller"
        @stop-scroll="StopScroller"
        @start-scroll="StartScroller"
        @scroll-to="ScrollToEl"
        @set-breadcrumb="SetBreadCrumb"
      />
      <MainFooter />
    </div>
  </div>
</template>

<style src="@/assets/css/output.css"></style>

<script>
import { ImageLoader } from '@/gsap/image_loaded';
import MainFooter from '@/components/MainFooter.vue';
import MainHeader from '@/components/MainHeader.vue';
import MainDialog from '@/components/MainDialog.vue';
import MainLoading from '@/components/MainLoading.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import { loadGtag } from '@/common/gtm_methods';
export default {
  name: 'App',
  components: {
    MainFooter,
    MainHeader,
    MainDialog,
    MainLoading,
    BreadCrumb,
  },
  data() {
    return {
      messenger_hvoer: false,
      first_time_load: true,
      marquee_animation: null,
      image_loader: null,
      bread_crumb_path: [
        {
          title: '首頁',
          link: '/',
        },
      ],
    };
  },
  methods: {
    LoadImage() {
      this.$nextTick(() => {
        this.image_loader.LoadImage();
      });
    },
    UpdateScroller() {
      this.image_loader.locoScroll.update();
    },
    StartScroller() {
      this.image_loader.locoScroll.start();
    },
    StopScroller() {
      this.image_loader.locoScroll.stop();
    },
    ScrollToTop() {
      this.image_loader.locoScroll.scrollTo('top', { duration: 100 });
    },
    ScrollToEl(id) {
      this.image_loader.locoScroll.scrollTo(`${id}`, {
        duration: 100,
        offset: -110,
      });
    },
    SetBreadCrumb(val) {
      this.$set(this, 'bread_crumb_path', val);
    },
    CheckPageData() {
      this.common_column_data == null
        ? this.$store.dispatch('getColumnData')
        : '';
      this.carousel_data == null ? this.$store.dispatch('getCarouselData') : '';
      this.news_data == null ? this.$store.dispatch('getNewsData') : '';
      this.news_category_data == null
        ? this.$store.dispatch('getNewsCategoryData')
        : '';
      this.product_data == null ? this.$store.dispatch('getProductData') : '';
      this.question_category_data == null
        ? this.$store.dispatch('getQuestionCategoryData')
        : '';
      this.question_data == null ? this.$store.dispatch('getQuestionData') : '';
    },
  },
  computed: {
    data_load_finish() {
      return this.$store.getters.data_load_finish;
    },
  },
  watch: {
    data_load_finish() {
      // if (this.data_load_finish) {
      //   this.LoadImage('App');
      // }
      if (this.data_load_finish && this.first_time_load) {
        this.first_time_load = false;
      }
    },
    $route() {
      // this.$nextTick(() => {
      //   this.ScrollToTop();
      // });
    },
  },
  created() {
    loadGtag('GTM-PLKV757');
    this.CheckPageData();
  },
  mounted() {
    this.image_loader = new ImageLoader();
  },
};
</script>
