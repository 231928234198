<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full mb-20 bg-bg_white xl:bg-main_white"
  >
    <Skeleton v-show="!block_ready" />
    <div
      :class="block_ready ? 'relative' : 'absolute top-0 left-0'"
      class="w-full overflow-hidden"
    >
      <div
        data-carousel-progress
        class="absolute top-0 bottom-0 left-0 z-10 w-1 bg-primary"
      ></div>
      <ol
        class="absolute z-10 flex items-center sm:bottom-5 sm:right-5 bottom-3 right-3"
      >
        <li
          @click="ChangeCarousel(item_index)"
          v-for="(item, item_index) in carousel_data"
          :key="`bullet_${item_index}`"
          :class="active_index == item_index ? '' : 'bg-opacity-30'"
          class="w-2 h-2 mx-1 rounded-full cursor-pointer sm:mx-2 sm:w-3 sm:h-3 bg-primary"
        ></li>
      </ol>
      <ul class="relative z-0 flex w-full h-fit">
        <li
          data-carousel-item
          v-for="(item, item_index) in carousel_data"
          :key="`carousel_${item_index}`"
          class="flex-shrink-0 w-full h-fit"
        >
          <a class="relative block w-full" :href="item.Link">
            <img
              :src="$ImageUrl(item.Image2)"
              :alt="$GetColumn('company_name') + ' ' + item.Title"
              class="block w-full h-auto md:hidden"
            />
            <img
              :src="$ImageUrl(item.Image1)"
              :alt="$GetColumn('company_name') + ' ' + item.Title"
              class="hidden w-full h-auto md:block"
            />
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Skeleton from '@/components/home/Skeleton/Carousel.vue';
import { carousel_animation } from '@/gsap/home/carousel';
export default {
  name: 'HomeCarousel',
  components: {
    Skeleton,
  },
  data() {
    return {
      carousel_animation: null,
      block_ready: false,
    };
  },
  methods: {
    SetGsap() {
      this.block_ready = true;
      this.carousel_animation = new carousel_animation(this.$refs.MainContent);
    },
    ChangeCarousel(index) {
      this.carousel_animation.active_index = index;
      this.carousel_animation.changeCarousel();
    },
  },
  computed: {
    carousel_data() {
      return this.$store.state.carousel_data;
    },
    active_index() {
      if (this.carousel_animation == null) {
        return -1;
      }
      return this.carousel_animation.active_index;
    },
  },
};
</script>
