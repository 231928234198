import { get, post } from '@/common/request';

export function getCarouselData() {
  return get('/carousel');
}

export function getNewsData() {
  return get('/news');
}

export function getColumnData() {
  return get('/column');
}

export function getNewsCategoryData() {
  return get('/news/category');
}

export function getProductData() {
  return get('/goods');
}
export function getSingleProductData(id) {
  return get(`/goods/${id}`);
}

export function getQuestionData() {
  return get('/question');
}

export function getQuestionCategoryData() {
  return get('/question/category');
}

export function postContact(data) {
  const form = {
    Name: data.name,
    Phone: data.phone,
    Email: '',
    Comment: data.comment,
  };
  return post('/contactUs', form);
}
