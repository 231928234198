import { gsap } from '@/gsap/gsap_loader';

export class parallax_image {
  constructor(el) {
    this.el = el;
    this.parallax_image = el.querySelectorAll('[data-parallax-image]');

    this.timeline = null;
    this.setup();
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : '';

    this.timeline = gsap.timeline({
      scrollTrigger: {
        scroller: '#app',
        trigger: this.el,
        start: 'top 70%',
        end: 'bottom 30%',
        scrub: true,
      },
    });

    this.parallax_image.forEach((item) => {
      const { parallaxStart, parallaxEnd } = item.dataset;
      this.timeline.fromTo(
        this.parallax_image,
        {
          y: parallaxStart || '0%',
        },
        {
          y: parallaxEnd || '-50%',
          ease: 'none',
        },
        'same'
      );
    });
  }
}
