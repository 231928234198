import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/service_list',
    name: 'service_list',
    component: () => import('../views/ServiceListView.vue'),
  },
  {
    path: '/service/:id',
    name: 'service_page',
    component: () => import('../views/ServiceView.vue'),
  },
  {
    path: '/case_list',
    name: 'case_list',
    component: () => import('../views/CaseListView.vue'),
  },
  {
    path: '/case/:id',
    name: 'case_page',
    component: () => import('../views/CaseView.vue'),
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('../views/QuestionView.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
  },
  {
    path: '/error_page',
    name: 'error_page',
    component: () => import('../views/ErrorView.vue'),
  },
  {
    path: '*',
    redirect: '/error_page',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // if (to.hash) {
    //   return {
    //     selector: to.hash,
    //     offset: { x: 0, y: 100 },
    //   };
    // }
    return { x: 0, y: 0 };
  },
});

export default router;
