import { gsap } from '@/gsap/gsap_loader';

export class carousel_animation {
  constructor(el) {
    this.el = el;
    this.carousel_item = el.querySelectorAll('[data-carousel-item]');
    this.carousel_progress = el.querySelectorAll('[data-carousel-progress]');
    this.timeline = null;
    this.progress_timeline = null;

    this.active_index = 0;
    this.timer = null;
    this.setup();
  }

  setup() {
    this.setTimer();
  }

  setTimer() {
    this.timer != null ? clearTimeout(this.timer) : '';
    this.setProgress();
    this.timer = setTimeout(() => {
      this.active_index =
        this.active_index == this.carousel_item.length - 1
          ? 0
          : this.active_index + 1;
      this.changeCarousel();
    }, 5000);
  }

  setProgress() {
    this.progress_timeline != null ? this.progress_timeline.kill() : '';
    this.progress_timeline = gsap.timeline();
    this.progress_timeline.fromTo(
      this.carousel_progress,
      {
        scaleY: 0,
        transformOrigin: 'top',
      },
      {
        scaleY: 1,
        transformOrigin: 'top',
        duration: 5,
        ease: 'none',
      }
    );
  }

  changeCarousel() {
    this.timer != null ? clearTimeout(this.timer) : '';
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    this.timeline.to(this.carousel_item, {
      x: `${this.active_index * -100}%`,
      onComplete: () => {
        this.setTimer();
      },
    });
  }
}
