<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full max-w-screen-xl mx-auto"
  >
    <div class="w-full px-7 md:px-10 lg:px-14 pb-28 md:pb-40 pt-14 md:pt-20">
      <header class="w-full mb-10">
        <small class="block overflow-hidden">
          <span
            data-section-subtitle
            class="block text-base font-black md:text-xl font-red_hat text-primary"
            >Process</span
          >
        </small>
        <h2 class="mb-5 overflow-hidden md:mb-0">
          <span
            data-section-title
            class="block text-3xl font-black md:text-4xl text-main_black"
            >貸款流程</span
          >
        </h2>
      </header>

      <div class="relative w-full">
        <Skeleton v-show="!block_ready" />

        <ul
          :class="
            block_ready
              ? 'relative z-10'
              : 'absolute top-0 left-0 z-0 opacity-0'
          "
          class="w-full flex flex-col lg:flex-row border-[1.5px] md:border-2 border-primary"
        >
          <li
            class="lg:w-1/3 w-full lg:aspect-square flex flex-col border-b-[1.5px] md:border-b-2 lg:border-b-0 lg:border-r-2 border-primary"
          >
            <div data-process-item class="w-full p-5 md:p-7">
              <h3
                class="w-full mb-10 text-[18px] md:text-xl font-black text-main_black"
              >
                <small
                  class="block w-full text-2xl font-black font-red_hat md:text-3xl text-primary"
                  >STEP 1.</small
                >
                {{ $GetColumn('process_1_title') }}
              </h3>
              <div class="flex flex-col w-full lg:justify-center lg:grow">
                <div class="w-full mb-10 h-fit">
                  <img
                    :src="$ImageUrl($GetColumn('process_1_image'))"
                    :alt="$GetColumn('process_1_title')"
                    class="block h-auto mx-auto w-28 md:w-36"
                  />
                </div>
                <div class="flex w-full">
                  <router-link
                    to="/contact"
                    class="block w-full py-3 mr-2 text-sm text-center text-main_white bg-primary hover:bg-main_hover btn_hover"
                    >填寫表單</router-link
                  >
                  <a
                    :href="`tel://${$GetColumn('company_phone')}`"
                    target="_blank"
                    class="block w-full py-3 text-sm text-center text-main_white bg-primary hover:bg-main_hover btn_hover"
                    >聯繫專人</a
                  >
                </div>
              </div>
            </div>
          </li>
          <li
            class="lg:w-1/3 w-full lg:aspect-square flex flex-col border-b-[1.5px] md:border-b-2 lg:border-b-0 lg:border-r-2 border-primary"
          >
            <div data-process-item class="w-full p-5 md:p-7">
              <h3
                class="w-full mb-10 text-[18px] md:text-xl font-black text-main_black"
              >
                <small
                  class="block w-full text-2xl font-black font-red_hat md:text-3xl text-primary"
                  >STEP 2.</small
                >
                {{ $GetColumn('process_2_title') }}
              </h3>
              <div class="flex flex-col w-full lg:justify-center lg:grow">
                <div class="w-full mb-5 h-fit md:mb-10">
                  <img
                    :src="$ImageUrl($GetColumn('process_2_image'))"
                    :alt="$GetColumn('process_2_title')"
                    class="block h-auto mx-auto w-28 md:w-36"
                  />
                </div>
              </div>
            </div>
          </li>
          <li class="flex flex-col w-full lg:w-1/3">
            <div data-process-item class="w-full p-5 md:p-7">
              <h3
                class="w-full mb-10 text-[18px] md:text-xl font-black text-main_black"
              >
                <small
                  class="block w-full text-2xl font-black font-red_hat md:text-3xl text-primary"
                  >STEP 3.</small
                >
                {{ $GetColumn('process_3_title') }}
              </h3>
              <div class="flex flex-col w-full lg:justify-center lg:grow">
                <div class="w-full mb-5 h-fit md:mb-10">
                  <img
                    :src="$ImageUrl($GetColumn('process_3_image'))"
                    :alt="$GetColumn('process_3_title')"
                    class="block h-auto mx-auto w-28 md:w-36"
                  />
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import Skeleton from '@/components/home/Skeleton/Process.vue';
import { section_animation } from '@/gsap/home/section';
import { process_animation } from '@/gsap/home/process';
export default {
  name: 'HomeProcess',
  components: {
    Skeleton,
  },
  data() {
    return {
      section_animation: null,
      process_animation: null,
      block_ready: false,
    };
  },
  methods: {
    SetGsap() {
      this.block_ready = true;

      this.$nextTick(() => {
        this.section_animation = new section_animation(this.$refs.MainContent);
        this.process_animation = new process_animation(this.$refs.MainContent);
      });
    },
  },
};
</script>
