import { gsap } from '@/gsap/gsap_loader';

export class service_animation {
  constructor(el) {
    this.el = el;
    this.service_item = el.querySelectorAll('[data-service-item]');

    this.timeline = null;
    this.setup();
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline({
      scrollTrigger: {
        scroller: '#app',
        trigger: this.el,
        start: 'top 70%',
        toggleActions: 'restart play play reverse',
      },
    });

    this.timeline.fromTo(
      this.service_item,
      {
        opacity: 0,
        x: '-50%',
      },
      {
        opacity: 1,
        x: '0%',
        stagger: 0.2,
      },
      0
    );
  }
}
