import Vue from 'vue';
import Vuex from 'vuex';
import {
  getCarouselData,
  getColumnData,
  getNewsCategoryData,
  getNewsData,
  getProductData,
  getQuestionCategoryData,
  getQuestionData,
} from '@/api/page_data';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    main_dialog: {
      status: false,
      content: '',
    },
    image_loaded: false,
    loading: 0,
    common_column_data: null,
    carousel_data: null,
    news_data: null,
    news_category_data: null,
    question_category_data: null,
    question_data: null,
    product_data: null,
  },
  getters: {
    data_load_finish(state) {
      if (
        state.common_column_data != null &&
        state.carousel_data != null &&
        state.news_data != null &&
        state.news_category_data != null &&
        state.product_data != null &&
        state.question_category_data != null &&
        state.question_data != null &&
        state.product_data != null
      ) {
        return true;
      } else {
        return false;
      }
    },
    getCommonColumn: (state) => (key) => {
      if (state.common_column_data == null) {
        return null;
      }
      const column_data = state.common_column_data.filter(
        (column) => column.Title == key
      );
      return column_data.length > 0 ? column_data[0].Content : '';
    },
  },
  mutations: {
    SetImageLoaded(state, action) {
      state.image_loaded = action;
    },
    SetLoading(state, action) {
      if (action == 1) {
        state.loading += 1;
      } else {
        state.loading > 0 ? (state.loading -= 1) : '';
      }
    },
    SetDialog(state, { status, content }) {
      state.main_dialog.status = status;
      state.main_dialog.content = content;
    },
    SetStateData(state, { key, val }) {
      state[key] = val;
    },
  },
  actions: {
    getCarouselData: ({ commit }) => {
      getCarouselData().then((res) => {
        let tmp_carousel_data = res.data;
        tmp_carousel_data.forEach((item, item_index) => {
          let title = item.Link.split('</Title>')[0];
          title = title.split('<Title>')[1];
          let link = item.Link.split('</Link>')[0];
          link = link.split('<Link>')[1];

          tmp_carousel_data[item_index].Title = title;
          tmp_carousel_data[item_index].Link = link;
        });
        commit('SetStateData', {
          key: 'carousel_data',
          val: tmp_carousel_data,
        });
      });
    },
    getColumnData: ({ commit }) => {
      getColumnData().then((res) => {
        commit('SetStateData', { key: 'common_column_data', val: res.data });
      });
    },
    getNewsCategoryData: ({ commit }) => {
      getNewsCategoryData().then((res) => {
        commit('SetStateData', { key: 'news_category_data', val: res.data });
      });
    },
    getNewsData: ({ commit }) => {
      getNewsData().then((res) => {
        let news_data = res.data;
        news_data.forEach((item, item_index) => {
          let location = item.Content.split('</Location>')[0];
          location = location.split('<Location>')[1];
          let name = item.Content.split('</Name>')[0];
          name = name.split('<Name>')[1];
          let career = item.Content.split('</Career>')[0];
          career = career.split('<Career>')[1];
          let money = item.Content.split('</Money>')[0];
          money = money.split('<Money>')[1];
          let description = item.Content.split('</Description>')[0];
          description = description.split('<Description>')[1];

          let content = item.Content.split('</Description>')[1];

          news_data[item_index].Location = location;
          news_data[item_index].Name = name;
          news_data[item_index].Career = career;
          news_data[item_index].Money = money;
          news_data[item_index].Description = description;
          news_data[item_index].Content = content;
        });
        commit('SetStateData', { key: 'news_data', val: res.data });
      });
    },
    getProductData: ({ commit }) => {
      getProductData().then((res) => {
        // Memo1 = slogan subtitle warning
        // Memo3 = Tage$index
        let products = res.data;

        products.forEach((item, item_index) => {
          let slogan = item.Memo1.split('</Slogan>')[0];
          slogan = slogan.split('<Slogan>')[1];
          let sub_title = item.Memo1.split('</SubTitle>')[0];
          sub_title = sub_title.split('<SubTitle>')[1];
          let warning = item.Memo1.split('</Warning>')[0];
          warning = warning.split('<Warning>')[1];
          let tag_list = [];

          for (let i = 1; i < 4; i++) {
            let tag = item.Memo3.split(`</Tag${i}>`)[0];
            tag = tag.split(`<Tag${i}>`)[1];
            let text = tag.split('</Text>')[0];
            text = text.split('<Text>')[1];
            let icon = tag.split('</Icon>')[0];
            icon = icon.split('<Icon>')[1];
            tag_list.push({
              Text: text,
              Icon: icon,
            });
          }

          products[item_index].Slogan = slogan;
          products[item_index].SubTitle = sub_title;
          products[item_index].Warning = warning;
          products[item_index].Tags = tag_list;
        });
        commit('SetStateData', { key: 'product_data', val: res.data });
      });
    },
    getQuestionCategoryData: ({ commit }) => {
      getQuestionCategoryData().then((res) => {
        commit('SetStateData', {
          key: 'question_category_data',
          val: res.data,
        });
      });
    },
    getQuestionData: ({ commit }) => {
      getQuestionData().then((res) => {
        commit('SetStateData', { key: 'question_data', val: res.data });
      });
    },
  },
  modules: {},
});
