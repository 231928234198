<template>
  <section ref="MainContent" class="relative z-10 w-full bg-bg_white">
    <div
      class="container_special w-full xl:max-h-[660px] xl:aspect-[2/1] xl:pt-40 xl:pb-40 relative z-10"
    >
      <div class="relative w-full">
        <Skeleton v-show="!block_ready" />
        <div
          :class="
            block_ready
              ? 'relative opacity-100'
              : ' absolute top-0 left-0 opacity-0'
          "
          class="w-full xl:aspect-[2/1] relative xl:pr-14 flex z-0"
        >
          <div
            class="w-20 xl:block hidden absolute z-10 left-[60%] transform xl:-translate-x-14 top-0 bottom-0 bg-bg_white"
          ></div>
          <div
            class="absolute top-0 left-0 z-0 w-full h-full overflow-hidden opacity-30 xl:relative xl:opacity-100"
          >
            <img
              data-parallax-image
              data-parallax-start="0%"
              data-parallax-end="-10%"
              :src="$ImageUrl($GetColumn('about_pc_image'))"
              :alt="$GetColumn('about_title_1')"
              class="w-full h-[120%] object-cover xl:object-center xl:block hidden"
            />
            <img
              data-parallax-image
              data-parallax-start="0%"
              data-parallax-end="-10%"
              :src="$ImageUrl($GetColumn('about_mb_image'))"
              :alt="$GetColumn('about_title_1')"
              class="w-full h-[120%] object-cover xl:object-center xl:hidden block"
            />
          </div>
          <div
            class="w-full xl:w-[40%] xl:h-full md:px-10 xl:py-0 py-20 lg:px-14 xl:px-0 xl:absolute relative z-[15] bottom-0 xl:right-14"
          >
            <div
              class="flex flex-col w-full bg-opacity-0 xl:h-full p-7 md:p-10 xl:justify-center bg-bg_white xl:bg-opacity-80"
            >
              <header class="w-full mb-10">
                <h2
                  class="mb-5 text-3xl font-black md:mb-0 md:text-4xl text-main_black"
                >
                  <small
                    class="block text-base font-black text-justify md:text-xl font-red_hat text-primary"
                    >About</small
                  >
                  {{ $GetColumn('about_title_1') }}
                </h2>
              </header>
              <h3
                class="w-full mb-3 text-base font-black text-justify txt_el md:text-xl text-primary"
              >
                {{ $GetColumn('about_subtitle_1') }}
              </h3>
              <div
                v-html="$GetColumn('about_content_1')"
                class="w-full mb-10 text-sm text-justify text-black"
              ></div>
              <router-link
                to="/about"
                class="w-44 h-fit md:w-60 px-6 md:px-10 py-3 md:py-4 flex items-center justify-between text-primary border-[1.5px] md:border-2 border-primary hover:text-white hover:bg-primary transition-colors duration-500"
              >
                <span class="inline-block mr-5 text-sm font-bold"
                  >了解更多</span
                >
                <span class="text-sm font-bold icon-arrow"></span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Skeleton from '@/components/home/Skeleton/About.vue';
import { parallax_image } from '@/gsap/home/parallax_image';
export default {
  name: 'HomeAbout',
  components: {
    Skeleton,
  },
  data() {
    return {
      parallax_image: null,
      block_ready: false,
    };
  },
  methods: {
    SetGsap() {
      this.block_ready = true;
      this.$nextTick(() => {
        this.parallax_image = new parallax_image(this.$refs.MainContent);
      });
    },
  },
};
</script>
