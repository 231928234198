import { gsap } from '@/gsap/gsap_loader';

export class loading_animation {
  constructor(el) {
    this.el = el;
    this.background = el.querySelector('[data-loading-bg]');
    this.box = el.querySelector('[data-loading-box]');

    this.timeline = null;
  }

  open() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    this.timeline
      .set(
        this.el,
        {
          x: '-100%',
        },
        'first'
      )
      .fromTo(
        this.background,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
        'second'
      )
      .fromTo(
        this.box,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
        'second'
      );
  }
  close() {
    this.timeline != null ? this.timeline.kill() : '';
    this.timeline = gsap.timeline();

    this.timeline
      .fromTo(
        this.box,
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
        'first'
      )
      .fromTo(
        this.background,
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
        'first'
      )
      .set(
        this.el,
        {
          x: '0%',
        },
        'second'
      );
  }
}
