<template>
  <section ref="MainContent" class="relative z-10 w-full overflow-hidden">
    <div class="relative z-10 w-full">
      <Skeleton v-show="!block_ready" />
      <div
        :class="
          block_ready ? 'relative z-10' : ' absolute top-0 left-0 z-0 opacity-0'
        "
        class="w-full max-w-screen-xl mx-auto"
      >
        <div
          class="container_special w-full xl:max-h-[660px] xl:py-40 xl:aspect-[2/1] relative z-10"
        >
          <div
            class="w-full xl:h-auto h-full xl:aspect-[2/1] xl:pl-14 xl:py-0 py-20 flex relative z-10"
          >
            <div
              class="absolute top-0 left-0 z-0 w-full h-full overflow-hidden xl:relative"
            >
              <img
                data-parallax-image
                data-parallax-start="0%"
                data-parallax-end="-10%"
                :src="$ImageUrl($GetColumn('promise_pc_image'))"
                alt="鉅祥保證"
                class="w-full h-[120%] object-cover object-center brightness-90 mask1 xl:block hidden"
              />
              <img
                data-parallax-image
                data-parallax-start="0%"
                data-parallax-end="-10%"
                :src="$ImageUrl($GetColumn('promise_mb_image'))"
                alt="鉅祥保證"
                class="w-full h-[120%] object-cover object-center xl:hidden block"
              />
            </div>
            <div
              class="w-full xl:w-[40%] xl:h-full md:px-10 lg:px-20 xl:px-0 xl:absolute z-[15] bottom-0"
            >
              <div
                class="flex flex-col w-full xl:h-full p-7 md:p-10 xl:justify-center"
              >
                <header class="w-full mb-10">
                  <h2
                    class="mb-5 text-3xl font-black md:mb-0 md:text-4xl text-main_white"
                  >
                    <small
                      class="block text-base font-black text-justify md:text-xl font-red_hat text-main_white"
                      >We Promise You</small
                    >
                    鉅祥保證
                  </h2>
                </header>
                <ul class="w-full mb-10 txt_el">
                  <li
                    v-for="item in 5"
                    :key="`promise_${item}`"
                    class="flex items-center w-full mb-3"
                  >
                    <span class="w-5 mr-3 md:w-7 h-fit">
                      <img
                        src="/img/promise_icon_white.webp"
                        alt="鉅祥保證"
                        class="block w-full h-auto"
                      />
                    </span>
                    <p
                      class="w-full text-sm font-medium md:text-base text-main_white"
                    >
                      {{ $GetColumn(`promise_${item}`) }}
                    </p>
                  </li>
                </ul>
                <router-link
                  to="/about#Promise"
                  class="w-44 h-fit md:w-60 px-6 md:px-10 py-3 md:py-4 flex items-center justify-between text-main_white border-[1.5px] md:border-2 border-main_white hover:bg-main_white hover:text-primary btn_hover"
                >
                  <span class="inline-block mr-5 text-sm font-bold"
                    >了解更多</span
                  >
                  <span class="text-sm font-bold icon-arrow"></span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="block_ready"
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-center bg-cover"
      :style="`background-image: url(${$ImageUrl(
        $GetColumn('promise_pc_image')
      )})`"
    ></div>
  </section>
</template>

<style scoped>
.mask1 {
  -webkit-mask-image: url('@/assets/mask.png');
  mask-image: url('@/assets/mask.png');
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@media (max-width: 1280px) {
  .mask1 {
    -webkit-mask-image: none;
    mask-image: none;
    -webkit-mask-size: 100%;
    mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
}
</style>

<script>
import Skeleton from '@/components/home/Skeleton/Promise.vue';
import { parallax_image } from '@/gsap/home/parallax_image';
export default {
  name: 'HomePromise',
  components: {
    Skeleton,
  },
  data() {
    return {
      parallax_image: null,
      block_ready: false,
    };
  },
  methods: {
    SetGsap() {
      this.block_ready = true;
      this.$nextTick(() => {
        this.parallax_image = new parallax_image(this.$refs.MainContent);
      });
    },
  },
};
</script>
