<template>
  <header
    id="MainHeader"
    class="fixed top-0 left-0 right-0 z-40 w-full main_header"
  >
    <div
      ref="Marquee"
      class="relative z-10 flex w-full py-2 text-white bg-primary"
    >
      <p
        class="relative flex-shrink-0 inline-block text-sm min-w-screen whitespace-nowrap"
      >
        鉅祥國際理財反詐騙聲明，近期金融詐騙事件頻傳，在此聲明本公司絕不會要求提供提款卡、存摺、密碼等正本資料，如有任何疑慮，請撥打本公司電話或加入官方LINE詢問求證。
      </p>
      <!-- <p
        class="relative flex-shrink-0 inline-block text-sm min-w-screen whitespace-nowrap"
      >
        鉅祥國際理財反詐騙聲明，近期金融詐騙事件頻傳，在此聲明本公司絕不會要求提供提款卡、存摺、密碼等正本資料，如有任何疑慮，請撥打本公司電話或加入官方LINE詢問求證。
      </p> -->
    </div>
    <div
      class="relative z-10 flex items-center justify-between w-full h-20 mx-auto bg-main_white bg-opacity-90 md:h-28 pl-7 md:pl-10 xl:pl-14"
    >
      <span class="w-36 md:w-[200px] h-fit mr-3">
        <router-link @click.native="dropdown_open = false" to="/">
          <img
            src="/img/nav_logo.webp"
            alt="鉅祥國際有限公司"
            class="block w-full h-auto transition-all duration-300 transform opacity-100 cursor-pointer hover:opacity-90"
          />
        </router-link>
      </span>

      <nav class="flex h-full main_header_nav">
        <ul class="hidden h-full xl:flex">
          <li
            class="flex items-center justify-center w-20 h-full md:w-28 xl:w-32"
          >
            <router-link
              to="/about"
              @click.native="dropdown_open = false"
              class="flex items-center justify-center w-full h-full px-2 py-2 text-base font-bold text-primary hover:text-main_hover btn_hover"
              >關於我們</router-link
            >
          </li>
          <li
            class="flex items-center justify-center w-20 h-full nav_service_btn md:w-28 xl:w-32"
          >
            <button
              @click="dropdown_open = !dropdown_open"
              class="flex items-center justify-center w-full h-full py-2 pl-2 pr-1 text-base font-bold text-primary hover:text-main_hover btn_hover"
            >
              服務項目
              <span class="pl-1 text-xl icon-nav_arrow"></span>
            </button>
          </li>
          <li
            class="flex items-center justify-center w-20 h-full md:w-28 xl:w-32"
          >
            <router-link
              to="/case_list"
              @click.native="dropdown_open = false"
              class="flex items-center justify-center w-full h-full px-2 py-2 text-base font-bold text-primary hover:text-main_hover btn_hover"
              >鉅祥部落格</router-link
            >
          </li>
          <li
            class="flex items-center justify-center w-20 h-full md:w-28 xl:w-32"
          >
            <router-link
              to="/question"
              @click.native="dropdown_open = false"
              class="flex items-center justify-center w-full h-full px-2 py-2 text-base font-bold text-primary hover:text-main_hover btn_hover"
              >常見問題</router-link
            >
          </li>
        </ul>
        <router-link
          to="/contact"
          @click.native="dropdown_open = false"
          class="flex flex-col items-center justify-center w-20 h-full nav_contact_btn md:w-28 xl:w-32 bg-primary hover:bg-main_hover btn_hover"
        >
          <span
            class="w-full text-2xl text-center icon-nav_contact text-main_white"
          ></span>
          <p class="hidden text-sm md:block xl:text-base text-main_white">
            聯繫我們
          </p>
        </router-link>
        <button
          v-show="!menu_open"
          @click="menu_open = true"
          class="flex flex-col items-center justify-center w-20 h-full main_menu_btn md:w-28 xl:w-32 xl:hidden"
        >
          <span
            class="block text-xl icon-nav_menu md:text-2xl text-primary"
          ></span>
          <span
            class="hidden text-xl icon-close md:text-2xl text-main_white"
          ></span>
        </button>
        <button
          v-show="menu_open"
          @click="menu_open = false"
          class="flex flex-col items-center justify-center w-20 h-full menu_close_btn md:w-28 xl:w-32 xl:hidden bg-primary"
        >
          <span
            class="hidden text-xl icon-nav_menu md:text-2xl text-primary"
          ></span>
          <span
            class="block text-xl icon-close md:text-2xl text-main_white"
          ></span>
        </button>
      </nav>
    </div>
    <MainMenu :menu_open="menu_open" @set-open="menu_open = $event" />
    <MainHeaderDropdown
      @close-action="dropdown_open = false"
      :dropdown_open="dropdown_open"
    />
  </header>
</template>

<script>
import MainMenu from "@/components/MainMenu.vue";
import MainHeaderDropdown from "@/components/MainHeaderDropdown.vue";
import { header_marquee_animation } from "@/gsap/header_marquee";
export default {
  name: "MainHeader",
  components: {
    MainMenu,
    MainHeaderDropdown,
  },
  data() {
    return {
      menu_open: false,
      dropdown_open: false,
      marquee_gsap: null,
    };
  },
  mounted() {
    this.marquee_gsap = new header_marquee_animation(this.$refs.Marquee);
    this.marquee_gsap.setup();
  },
};
</script>
